import React from "react";
import { css } from "theme-ui";
import { Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import blogStyles from "./blog.module.scss";
import Footer from "../gatsby-theme-blog/components/home-footer.js";
const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "MMMM Do, YYYY")
          }
        }
      }
      site {
        siteMetadata {
          title
          social {
            url
            name
          }
        }
      }
    }
  `);

  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      {/* <Head title="Blog" /> */}
      <h1>Content Feed</h1>
      <ol className={blogStyles.posts}>
        {data.allContentfulBlogPost.edges.map(edge => {
          return (
            <li className={blogStyles.post}>
              <Link to={`/${edge.node.slug}`}>
                <h2>{edge.node.title}</h2>
                <p>{edge.node.publishedDate}</p>
              </Link>
            </li>
          );
        })}
      </ol>
      <Footer socialLinks={data.site.siteMetadata.social} />
    </Layout>
  );
};

export default IndexPage;
